import React from 'react';

import LoginForm from './LoginForm'

export default function Login( { redirect } ) {

	return (
		<>
			<div className="login">
				<div className="login__container">

					<h2 className="login__heading">Existing Customer</h2>
					<h3 className="login__sub-heading">Sign in with email</h3>

					<LoginForm cssClass="login__form" redirect={ redirect } />

					{/* <span className="login__divider"><span className="login__divider-text">OR</span></span>

					<h3 className="login__sub-heading">Sign in with...</h3> */}
				</div>
			</div>
		</>
	)
}
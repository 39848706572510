import React, { useEffect } from "react"
import { navigate } from "gatsby"

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import InfoCard from '../components/InfoCard/InfoCard'
import Login from '../components/Login/Login'
import RegisterBox from '../components/Register/RegisterBox'

import useAuth from "../hooks/useAuth"

export default function LoginPage( { location } ) {

	const { state: routeState } = location;
	const { isAuthenticated } = useAuth()

	const redirect = !routeState
		? '/account'
		: routeState.redirect === 'account'
		? '/account'
		: `/account/${routeState.redirect}`;

	useEffect( () => {
		if ( isAuthenticated ) {
			navigate( '/account' );
		}
	}, [ isAuthenticated ] );

	return (
		<>
			<SEO
				title="Login"
				pathname={ location.pathname } />
			<Breadcrumb pathname={ location.pathname } />
			<div className="wrapper">
				<InfoCard />

				<div className="login-page">
					<div className="login-page__register-container">
						<Login redirect={ redirect } />
					</div>
					<div className="login-page__login-container">
						<RegisterBox redirect={ redirect } />
					</div>
					
				</div>
			</div>
		</>
	);
}
import React from 'react'

import { Link } from 'gatsby'

export default function RegisterBox() {

	return (
		<div className="register-box">
			<h2 className="register-box__heading">New Customers</h2>
			<h3 className="register-box__sub-heading">Create a Hoarders account today!</h3>

			<Link to="/register" className="button button--secondary button--no-margin">
				Register Now
			</Link>
		</div>
	)
}